import { RouteGroup } from "../types/side-nav-props";
import { Link, Stack, Typography, List, ListItem } from "@lavanda-uk/xenia-ui";
import { Link as NextLink } from "@xenia-libs/resident-app-ui/link";

export type NavGroupsProps = {
  routeGroups: RouteGroup[];
};

export function NavGroups({ routeGroups }: NavGroupsProps) {
  return (
    <Stack gap={4}>
      {routeGroups.map((group, index) => {
        if (
          group.routes.length === 0 ||
          group.routes.every((r) => r.isHidden)
        ) {
          return null;
        }

        return (
          <Stack key={index} gap={2}>
            <Typography
              color="inherit"
              data-testid={`group-title-${group.id}`}
              noWrap
            >
              {group.label}
            </Typography>
            <List
              disablePadding
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              {group.routes.map((route) => {
                if (route.isHidden) {
                  return null;
                }

                return (
                  <ListItem key={route.href} disablePadding>
                    <Link
                      component={NextLink}
                      href={route.href}
                      color="inherit"
                      width="100%"
                      display="inline-block"
                      onClick={route.onClick}
                      aria-current={route.isCurrent ? "page" : undefined}
                      fontSize="1.3rem"
                      data-testid={`nav-link-${group.id}-${route.id}`}
                      noWrap
                    >
                      {route.label}
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </Stack>
        );
      })}
    </Stack>
  );
}
