import { AvailableParams, Params } from "@xenia-libs/resident-app-utils/types";

type ParentOptions = Partial<Pick<Params, AvailableParams.BOOKING_ID>>;

export type Path = {
  /**
   * The parent path to use
   */
  parent: string | ((opts: ParentOptions) => string);
  /**
   * What paths to match against
   */
  match: RegExp | RegExp[];
};

/**
 * Defines the relationship between a path and its parent. This isn't necessarily a direct
 * parent in terms of the URL structure, but rather a logical parent that we can use to
 * navigate back to a previous page.
 */
export const parentPaths: Path[] = [
  {
    // Booking details and account
    match: [/\/bookings\/\d+$/i, /account$/i],
    parent: "/dashboard",
  },
  {
    // Article
    match: /\/articles\/[a-z0-9]+$/i,
    parent: "/help-centre",
  },
];
