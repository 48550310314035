"use client";
import { Header } from "@xenia-libs/resident-app-ui/header";
import { SideNav } from "@xenia-libs/resident-app-ui/side-nav";
import { useNavStore } from "@xenia-libs/resident-app-ui/stores/navigation.store";
import { PropsWithChildren, useCallback } from "react";
import { useRoutes } from "../hooks/use-routes";
import { Box, useMuiTheme } from "@lavanda-uk/xenia-ui";
import { useMediaQuery } from "@xenia-libs/hooks";

export type ClientNavigationProps = PropsWithChildren<{
  logoSrc: string | undefined;
  brandName: string;
  accountDetails: React.ReactNode;
}>;

export const HEADER_HEIGHT = "55px";

export function ClientNavigationLayout(props: ClientNavigationProps) {
  const isNavOpen = useNavStore((state) => state.isNavOpen);
  const setNavOpen = useNavStore((state) => state.setNavOpen);
  const toggleNavOpen = useNavStore((state) => state.toggleNavOpen);
  const theme = useMuiTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("mobile"));

  const handleRouterClick = useCallback(() => {
    setNavOpen(false);
  }, [setNavOpen]);

  const { routeGroups } = useRoutes({
    onClick: handleRouterClick,
  });

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.up("mobile")]: {
          display: "flex",
          width: "100svw",
          height: "100svh",
        },
      })}
    >
      <SideNav top={HEADER_HEIGHT} routeGroups={routeGroups} isOpen={isNavOpen}>
        {props.accountDetails}
      </SideNav>

      <Box
        flex={1}
        sx={(theme) => ({
          [theme.breakpoints.up("mobile")]: {
            overflow: "auto",
          },
        })}
      >
        <Header
          height={HEADER_HEIGHT}
          logoSrc={props.logoSrc}
          brandName={props.brandName}
          onMenuClick={toggleNavOpen}
          isNavOpen={isNavOpen}
        />

        <Box
          component="main"
          p={4}
          maxWidth="1100px"
          mx="auto"
          // React still doesn't like the inert attribute, so we have to use ts-ignore
          // and also set the attribute to an empty string instead of true
          // @ts-expect-error inert is a valid HTML attribute
          inert={isNavOpen && !isDesktop ? "" : undefined}
          sx={(theme) => ({
            [theme.breakpoints.up("mobile")]: {
              px: 12,
            },
          })}
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}
