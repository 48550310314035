import {
  redirect as nextRedirect,
  permanentRedirect as nextPermanentRedirect,
} from "next/navigation";
import { getFromResidentAppHref } from "../get-from-resident-app-href";

export const redirect: typeof nextRedirect = (url, type) =>
  nextRedirect(getFromResidentAppHref(url), type);

export const permanentRedirect: typeof nextPermanentRedirect = (url, type) =>
  nextPermanentRedirect(getFromResidentAppHref(url), type);
