import { create } from "zustand";

export type NavStore = {
  isNavOpen: boolean;
  toggleNavOpen: () => void;
  setNavOpen: (isOpen: boolean) => void;
};

export const useNavStore = create<NavStore>((set) => ({
  isNavOpen: false,
  toggleNavOpen: () => {
    set((state) => ({ isNavOpen: !state.isNavOpen }));
  },
  setNavOpen: (isOpen) => {
    set({ isNavOpen: isOpen });
  },
}));
