"use client";
import { Box, Stack } from "@lavanda-uk/xenia-ui";
import {
  AnimatePresence,
  motion,
  useReducedMotion,
  Variants,
} from "framer-motion";
import { SideNavProps } from "./types/side-nav-props";
import { NavGroups } from "./components/nav-groups";
import { PropsWithChildren } from "react";

const VARIANTS: Variants = {
  open: { x: 0 },
  closed: { x: "-100%" },
};

function NavContent({
  routeGroups,
  children,
}: PropsWithChildren<Pick<SideNavProps, "routeGroups">>) {
  return (
    <>
      <Box
        sx={(theme) => ({
          background: theme.palette.tertiary.main,
          color: theme.palette.tertiary.contrastText,
        })}
      >
        {children}
      </Box>

      <Box
        component="nav"
        sx={(theme) => ({
          background: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
        })}
        flex={1}
      >
        <NavGroups routeGroups={routeGroups} />
      </Box>
    </>
  );
}

export function SideNav({
  children,
  routeGroups,
  isOpen,
  top = "0px",
}: SideNavProps) {
  const prefersReducedMotion = useReducedMotion();

  return (
    <>
      <Box
        data-testid="side-nav-mobile"
        sx={(theme) => ({
          [theme.breakpoints.up("mobile")]: {
            display: "none",
          },
        })}
      >
        <AnimatePresence>
          {isOpen && (
            <Stack
              left={0}
              right={0}
              bottom={0}
              top={top}
              component={motion.div}
              initial="closed"
              animate="open"
              exit="closed"
              variants={VARIANTS}
              transition={{
                duration: prefersReducedMotion ? 0 : 0.25,
              }}
              position="fixed"
              overflow="auto"
              sx={(theme) => ({
                zIndex: theme.zIndex.drawer,
                "& > *": {
                  p: 3,
                },
              })}
            >
              <NavContent routeGroups={routeGroups}>{children}</NavContent>
            </Stack>
          )}
        </AnimatePresence>
      </Box>

      <Box
        data-testid="side-nav-desktop"
        display="none"
        height="100%"
        width="clamp(200px, 25%, 320px)"
        sx={(theme) => ({
          [theme.breakpoints.up("mobile")]: {
            display: "block",
          },
        })}
      >
        <Stack
          overflow="auto"
          height="100%"
          sx={{
            "& > *": {
              p: 3,
            },
          }}
        >
          <NavContent routeGroups={routeGroups}>{children}</NavContent>
        </Stack>
      </Box>
    </>
  );
}
