import { useTranslations } from "@xenia-libs/next-i18n";
import { Route, RouteGroup } from "@xenia-libs/resident-app-ui/side-nav";
import { usePathname } from "@xenia-libs/resident-app-utils/navigation";
import { useMemo } from "react";
import { useCookies } from "next-client-cookies";
import { SELECTED_BOOKING_ID_COOKIE_NAME } from "@xenia-libs/resident-app-utils/constants";

export type UseRoutesProps = {
  onClick?: Route["onClick"];
};

export function useRoutes({ onClick }: UseRoutesProps) {
  const t = useTranslations("features.navigation");
  const pathname = usePathname();
  const cookies = useCookies();
  const bookingId = cookies.get(SELECTED_BOOKING_ID_COOKIE_NAME);

  const routeGroups = useMemo<RouteGroup[]>(() => {
    return [
      {
        id: "your-stay",
        label: t("your_stay.title"),
        routes: [
          {
            id: "dashboard",
            href: "/dashboard",
            label: t("dashboard.title"),
            isHidden: !bookingId,
          },
          {
            id: "help-centre",
            href: "/help-centre",
            label: t("help_centre.title"),
            isHidden: !bookingId,
          },
        ],
      },
      {
        id: "booking",
        label: t("booking.title"),
        routes: [
          {
            id: "bookings",
            href: `/bookings`,
            label: t("switch_booking.title"),
          },
          {
            id: "booking-details",
            href: `/bookings/${bookingId}`,
            label: t("booking_details.title"),
            isHidden: !bookingId,
          },
        ],
      },
    ].map((group) => ({
      ...group,
      routes: group.routes.map((route) => ({
        ...route,
        isCurrent: route.href === pathname,
        onClick,
      })),
    }));
  }, [bookingId, onClick, pathname, t]);

  return { routeGroups };
}
