"use client";

import { getFromResidentAppHref } from "@xenia-libs/resident-app-utils/get-from-resident-app-href";
import { ComponentProps, forwardRef, useMemo } from "react";
// eslint-disable-next-line no-restricted-imports
import NextLink from "next/link";

export type LinkProps = ComponentProps<typeof NextLink>;

export const Link = forwardRef((props, ref) => {
  const href = useMemo(() => getFromResidentAppHref(props.href), [props.href]);
  return <NextLink {...props} ref={ref} href={href} />;
}) as typeof NextLink;
