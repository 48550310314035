import { usePathname } from "@xenia-libs/resident-app-utils/navigation";
import { useMemo } from "react";
import { parentPaths } from "./parent-paths";
import { useBookingId } from "../use-booking-id";

export const pathnameCache = new Map<string, string | undefined>();

export function useParentPath() {
  const pathname = usePathname();
  const bookingId = useBookingId();

  const parentPath = useMemo(() => {
    if (pathnameCache.has(pathname)) {
      return pathnameCache.get(pathname);
    }

    for (const path of parentPaths) {
      const regexpArr = Array.isArray(path.match) ? path.match : [path.match];

      if (regexpArr.some((r) => r.test(pathname))) {
        const result =
          typeof path.parent === "function"
            ? path.parent({ bookingId })
            : path.parent;
        const fullPath = `${result.startsWith("/") ? "" : "/"}${result}`;

        pathnameCache.set(pathname, fullPath);
        return fullPath;
      }
    }
  }, [bookingId, pathname]);

  return { parentPath };
}
